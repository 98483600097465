<template>
  <v-app>
    <div v-if="isLoading" class="my-14">
      <v-row justify="center" align="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </div>
    <!-- case of no user found -->
    <div v-if="!isLoading && !userDoc" class="col-12 pa-4">
      <v-alert type="error" shaped light prominent>
        <span> No user Found by this ID </span>
      </v-alert>
    </div>
    <!-- case of user has no subscription -->
    <div
      v-if="!isLoading && userDoc && !userDoc.subscription"
      class="col-12 pa-4"
    >
      <v-alert type="warning" shaped light prominent>
        <span> This user is not subscribed yet </span>
      </v-alert>
    </div>
    <!-- badge -->
    <div v-if="!isLoading && userDoc && userDoc.subscription">
      <div class="col-12 pa-4">
        <v-alert
          color="info"
          icon="mdi-shield-star-outline"
          shaped
          light
          prominent
          type="info"
        >
          <span>
            {{ userDoc.firstName }} Was issued this bagde of honor by Zero
          </span>
        </v-alert>
      </div>
      <div class="logo-container">
        <!-- todo : change with 50% badge -->
        <div class="logo-image" v-if="userDoc.subscription.percentage == 50">
          <v-img :src="require('@/assets/plan-logo.svg')"> </v-img>
        </div>

        <div class="logo-image" v-if="userDoc.subscription.percentage == 100">
          <v-img :src="require('@/assets/plan-logo.svg')"> </v-img>
        </div>

        <!-- todo : change with 150% badge -->
        <div class="logo-image" v-if="userDoc.subscription.percentage == 150">
          <v-img :src="require('@/assets/plan-logo.svg')"> </v-img>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  created() {
    // set the app bar title
    this.setCurrentAppBarTitle("Badge preview");

    // get UID from the route
    this.uid = this.$route.params.uid;
    this.fetchBageDetails();
  },
  computed: {
    ...mapGetters(["getUserFSDocument"]),
  },
  data() {
    return {
      isLoading: false,
      uid: null,
      userDoc: null,
    };
  },
  methods: {
    ...mapMutations(["setErrorSnackbarMessage", "setCurrentAppBarTitle"]),
    ...mapActions(["fetchUserFSDocumentByUID"]),
    async fetchBageDetails() {
      this.isLoading = true;
      try {
        this.userDoc = await this.fetchUserFSDocumentByUID(this.uid);
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.logo-image {
  margin-top: 10vh;
  width: 60%;
}
</style>