import { render, staticRenderFns } from "./BadgePreview.vue?vue&type=template&id=726d06f7&scoped=true&"
import script from "./BadgePreview.vue?vue&type=script&lang=js&"
export * from "./BadgePreview.vue?vue&type=script&lang=js&"
import style0 from "./BadgePreview.vue?vue&type=style&index=0&id=726d06f7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "726d06f7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VApp,VImg,VProgressCircular,VRow})
